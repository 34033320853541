/**
 * Toggles redux logging.
 */
export const REDUX_LOGGING_ENABLED = false;
/**
 * Levity widget properties.
 */
export const SITE_CODE = 'apfym';
export const APP_ID = 366;
/**
 * Specifies target endpoints for /browsercheck, student subject /home.
 */
export const STUDENT_DOMAIN_CHECK_LIST = {};
/**
 * Specifies critical endpoints for site functionality.
 */
export const VITAL_DOMAINS = [
  FYM_WIZARDS_BACKEND_URL,
  FYM_ACCOUNTS_URL,
  FYM_BACKEND_URL,
  FYM_FRONTEND_URL,
  FYM_UNITS_BACKEND_URL,
  TEACHER_SSO_LOGIN_URL,
  CB_LEGACY_URL,
];
/**
 * Teacher subject type -> view mapping.
 */
export const SUBJECT_TYPE_VIEW = {
  UNIT_ASSESSMENTS: 'fym',
  ASSESSMENTS: 'pre-ap',
  QUESTION_BANK: 'qb',
  PREAP: 'pre-ap',
};
/**
 * This is the PDF used as the LockDown Browser How-To Guide
 */
export const LDB_HOW_TO_GUIDE =
  'http://media.academicmerit.com/apclassroom/InstallingtheLockDownBrowserforAP%20Classroom.pdf';

/**
 * FAQ web page managed by CB
 */
export const LDB_FAQ_WEB_PAGE =
  'https://apclassroom.clickhelp.co/articles/#!ap-classroom-user-guide-for-teachers-publication/qb-about-the-lockdown-browser';

/**
 * Troubleshooting Link
 */
export const TROUBLESHOOTING_LINK =
  'https://apclassroom.clickhelp.co/articles/#!ap-classroom-user-guide-for-teachers-publication/qb-ldb-troubleshooting';

export const TROUBLESHOOTING_LINK_STUDENTS =
  'https://apclassroom.clickhelp.co/articles/#!ap-classroom-user-guide-for-students/stu-ldb-troubleshooting';
