const getTiers = (state) => state.performanceTiers.tiersData.performanceTiers;

/*
 * Calculates mastery
 * @param {number} score
 * */
const calcMastery = (score, performanceTiers) => {
  const { store } = window;
  const tiers = performanceTiers || getTiers(store.getState());
  if (tiers === undefined) {
    return undefined;
  }
  if (score === null || score === undefined) {
    return 'no_results';
  }

  return tiers.reduce((acc, tier) => {
    if (tier.bottomLimit <= score && tier.topLimit + 1) {
      return tier.color;
    }
    return acc;
  }, 'no_results');
};

export default calcMastery;
