/*

IMPORTANT: Be sure to keep this in sync with its pythonic sibling (apps/content/transformations.py)

Apply abitrary transformations to an item before it is rendered.

Intended uses:

1. Migrating items to updated schemas for backwards compatability.
2. Transforming higher level types into renderable types (quiz -> pageset/columns/pageset/etc...).

Tranformations are split between playback and editing because while some
operations will be shared (mostly stuff from #1), many of them only make sense
in a playback scenario.

*/
import _ from 'lodash';

const transformForPlayback = (original) => {
  if (!original) {
    throw new Error('Item missing for transformForPlayback');
  }

  let item = original;

  item = migrateContent(item);
  item = migrateType(item);
  item = migrateTitle(item);

  const compiler = compiledTypes[item.type];
  if (compiler) {
    item = compiler(item);
  }
  item = addId(item);
  item = transformChildren(item, transformForPlayback);

  return item;
};

const transformForEditing = (original) => {
  if (!original) {
    throw new Error('Item missing for transformForEditing');
  }
  let item = original;

  item = migrateContent(item);
  item = migrateType(item);
  item = transformChildren(item, transformForEditing);

  return item;
};

const migrateContent = (item) => {
  // Migrate item.item -> item.content
  let newItem = item;
  if (!item.content && item.item) {
    newItem = { ..._.omit(item, 'item'), content: item.item || {} };
  }
  // We put show_answers in a slightly weird place without knowing it- this fixes that.
  if (item.show_answers) {
    newItem = {
      ..._.omit(item, 'show_answers'),
      content: { ...item.content, show_answers: item.show_answers },
    };
  }
  return newItem;
};

const migrateType = (item) => {
  // Migrate item.content.type -> item.type
  if (!item.type && item.content && item.content.type) {
    return {
      ...item,
      type: item.content.type,
      content: _.omit(item.content, 'type'),
    };
  }
  return item;
};

const migrateTitle = (item) => {
  // Handle old, non-ProseMirror titles.
  try {
    if (item.title && typeof item.title === 'string') {
      item.title = JSON.parse(item.title);
    }
  } catch (e) {
    // Nothing to do here; this just means the title isn't JSON yet.
  }
  return item;
};

const transformChildren = (item, fn) => {
  // Recursively transform all child items
  if (item.content && item.content.items) {
    const items = item.content.items.map(fn);
    return { ...item, content: { ...item.content, items } };
  }
  return item;
};

const addId = (item) => {
  //  HACK: couldnt find api call that was returning only res and not res.data
  const result = item.data ? item.data : item;
  // This is primarily for embed items
  if (!result.id && result.content && result.content.id) {
    result.id = result.content.id;
  }
  return result;
};

const compiledTypes = {
  'composite/quiz': function (item) {
    return {
      ...item,
      type: 'containers/pageset',
      content: {
        items: item.content.items.map(quizSection),
      },
    };
  },
};

const quizSection = (item) => {
  if (item.questions && item.questions.length > 0) {
    return {
      type: 'containers/columns',
      content: {
        items: [
          item.stimulus,
          {
            type: 'containers/pageset',
            content: {
              items: item.questions,
            },
          },
        ],
      },
    };
  }
  return item.stimulus;
};

export { transformForEditing, transformForPlayback };
