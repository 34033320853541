import forEach from 'lodash/forEach';

/**
 * Class observable for detecting prop changing
 * and subscriber notifications (ReactDOM components)
 * */
class _Observable {
  constructor() {
    this.observables = {
      intervals: [],
      timeouts: [],
      timeElapsed: null,
      subscribers: {
        callbacks: {},
        props: {},
      },
    };
  }

  get(prop) {
    const { subscribers } = this.observables;

    return subscribers.props[prop];
  }

  /**
   * Emits to all subscribers the new value
   * @params {string} prop
   * @parmas {any} value
   * */
  emit(prop, value) {
    const { subscribers } = this.observables;
    const oldValue = subscribers.props[prop];
    /**
     * @note If the value is an object of methods JSON.stringify will fail
     * - recommandation is to use a function(not arrow function) for passing through the value
     * */
    const isFunction = typeof value === 'function';
    const isNewObjectValue = JSON.stringify(oldValue) !== JSON.stringify(value);
    const isNewValue = isFunction ? value !== oldValue : isNewObjectValue;
    if (isNewValue) {
      subscribers.props[prop] = value;

      const callbacks = subscribers.callbacks[prop];
      if (callbacks && callbacks.length) {
        callbacks.forEach((callback) => callback.call(null, value));
      }
    }
  }

  subscribe(prop, callback, clearPrevious) {
    const { subscribers } = this.observables;
    if (subscribers.props[prop] === undefined) {
      subscribers.props[prop] = null;
    }

    const { callbacks } = subscribers;
    if (callbacks[prop] === undefined || clearPrevious) {
      callbacks[prop] = [callback];
    } else {
      callbacks[prop].push(callback);
    }
  }

  unsubscribe(prop, callback) {
    const { subscribers } = this.observables;
    const callbacks = subscribers.callbacks[prop] || [];
    if (
      subscribers.props[prop] !== undefined &&
      callbacks.length > 0 &&
      callback
    ) {
      const funcIndex = callbacks.indexOf(callback);
      if (funcIndex !== -1) {
        this.observables.subscribers.callbacks = callbacks.splice(funcIndex, 1);
      }
    }
  }

  clearIntervals() {
    this.observables.intervals.forEach(
      (interval) => interval && window.clearInterval(interval),
    );
    this.observables.intervals = [];
  }

  clearTimeouts() {
    this.observables.timeouts.forEach(
      (timeout) => timeout && window.clearTimeout(timeout),
    );
    this.observables.timeouts = [];
  }

  updateObservable(updates) {
    forEach(updates, (value, callback) => {
      const func = this[`_${callback}`];
      if (func) {
        func.apply(this, value);
      }
    });
  }

  destroy() {
    this.clearIntervals();
    this.clearTimeouts();
    this.observables.subscribers.callbacks = {};
    this.observables.subscribers.props = {};
  }

  _add(updates) {
    forEach(updates, (value, location) => {
      if (this.observables.hasOwnProperty(location)) {
        this.observables[location].push(value);
      }
    });
  }

  _remove(updates) {
    forEach(updates, (value, location) => {
      if (this.observables.hasOwnProperty(location)) {
        const index = this.observables[location].indexOf(value);
        if (index !== -1) {
          this.observables[location].splice(index, 1);
        }
      }
    });
  }

  _update(updates) {
    forEach(updates, (value, location) => {
      if (this.observables.hasOwnProperty(location)) {
        this.observables[location] = value;
      }
    });
  }
}

export const observable = new _Observable();
