const forEachItem = (item, callback, path = [0]) => {
  callback(item, path);
  if (item.content.items) {
    item.content.items.forEach((child, index) => {
      forEachItem(child, callback, path.concat(index));
    });
  }
};

const forEachFromItems = (item, items, callback, path = [0]) => {
  /*
  Example:
    forEachFromItems(items[items._root_id], items, (item, path) => {
      // do stuff with item here
    })
  */
  callback(item, path);
  if (item.content.items) {
    item.content.items.forEach((child, index) => {
      if (child.type === 'special/embed') {
        const embeddedItem = items[child.content.id];
        forEachFromItems(embeddedItem, items, callback, path.concat(index));
      } else {
        forEachFromItems(child, items, callback, path.concat(index));
      }
    });
  }
};

const indexItems = (item) => {
  const items = {};
  forEachItem(item, (item, path) => {
    items[path.join('.')] = item;
  });
  return items;
};

export { forEachItem, forEachFromItems, indexItems };
